import React from "react"
import { RouteProps } from "react-router"

import { Avatar, Box, Typography } from '@material-ui/core'

import { AuthedUserModel } from "../models"

interface MyProps {
  authedUser: AuthedUserModel
}

export class AuthedUser extends React.Component<MyProps & RouteProps> {
  public render () {
    return (
      <Box
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Box p={1}>
          <Avatar alt={this.props.authedUser.username} src={this.props.authedUser.avatarURL} />
        </Box>
        <Box>
          <Typography component="h2" color="textPrimary">{this.props.authedUser.username}</Typography>
        </Box>
      </Box>
    )
  }
}
