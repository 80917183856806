import React, { useContext } from "react"

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { LoginCard } from "../components/LoginCard"
import { AuthContext } from "../auth/AuthContext"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    wrapper: {
      padding: "10px"
    }
  })
)

export const Home = () => {
  const classes = useStyles()
  const context = useContext(AuthContext)

  const loggedIn = !!context?.user

  return (
    <div>
      {loggedIn ? (
        <div>
          <p>You are logged in. Log in with another account to sync to this one.</p>
        </div>
      ) : (
          <div>
            <h3>Hi.</h3> <p>To get started, please log in with an auth provider.</p>
          </div>
        )}
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item lg={4} sm={12}>
            <div className={classes.wrapper}>
              <LoginCard icon="twitch" label="Twitch" path="/login/twitch" loggedIn={loggedIn} authedUsers={(context?.user?.authed?.twitch) || []} />
            </div>
          </Grid>
          <Grid item lg={4} sm={12}>
            <div className={classes.wrapper}>
              <LoginCard icon="discord" label="Discord" path="/login/discord" loggedIn={loggedIn} authedUsers={(context?.user?.authed?.discord) || []} />
            </div>
          </Grid>
          <Grid item lg={4} sm={12}>
            <div className={classes.wrapper}>
              <LoginCard icon="steam" label="Steam" path="/login/steam" loggedIn={loggedIn} authedUsers={(context?.user?.authed?.steam) || []} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
