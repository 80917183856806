import { useEffect, useContext } from "react"
import { useLocation } from "react-router-dom"

import { refreshToken } from "./Auth"
import { AuthContext } from "./AuthContext"

export function RouterChanged () {
  const location = useLocation()
  const context = useContext(AuthContext)

  useEffect(
    () => {
      console.log(location.pathname)
      if (location.pathname.startsWith("/login/")) return undefined
      // tslint:disable-next-line: no-floating-promises
      refreshToken(context)
    },
    [location, context]
  )
  return null
}
