import React from "react"
import ReactDOM from "react-dom"
import { Main } from "./layout/Main"
import * as serviceWorker from "./statics/serviceWorker"
import "./styles/index.css"

// import { initFirebase } from "./auth/Auth"

// initFirebase()

ReactDOM.render(<Main />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
