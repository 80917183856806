import React, { useContext } from "react"

import firebase from "firebase/app"

import { faPortalExit } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@material-ui/core/"

import { AuthContext } from "../auth/AuthContext"

export const Logout = () => {
  const context = useContext(AuthContext)

  const loggedIn = !!context?.user

  const signOut = () => {
    console.log("Signing out")
    firebase.auth().signOut()
    context?.setUser(null)
  }

  return loggedIn ? (
    <div>
      {/* Logged in as: {context?.user?.authed.twitch["62264032"].username} */}
      <Button className="login-button" disableElevation size="small" variant="contained" color="primary" onClick={signOut}>
        Logout&nbsp;<FontAwesomeIcon icon={faPortalExit} color="secondary" />
      </Button>
    </div>
  ) : null
}
