import React, { useState, createContext, FunctionComponent } from "react"
import { RouteProps } from "react-router"

import 'firebase/firestore'
import * as firebase from "firebase/app"

import { UserDoc } from "../models"

export interface AuthUserContext {
  user: UserDoc | null,
  setUser: React.Dispatch<React.SetStateAction<UserDoc | null>>
}

export const getFirestoreUser = async (user: firebase.User | null): Promise<UserDoc | null> => {
  const uid = user?.uid
  let setUserTo
  if (uid) {
    const user = await firebase.firestore().collection("users").doc(uid).get()
    const userData = user.data() as UserDoc
    setUserTo = userData
  } else {
    setUserTo = null
  }
  console.log("setUserTo :", setUserTo)
  return setUserTo
}

export const AuthContext = createContext<AuthUserContext | null>(null)

export const AuthProvider: FunctionComponent<RouteProps> = (props): JSX.Element => {

  const [user, setUser] = useState<UserDoc | null>(null)
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {/* USER: {user?.username} */}
      {props.children}
    </AuthContext.Provider>
  )
}
