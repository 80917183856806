import React from "react"

import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import config from "../auth/Firebase"

export const Login = ({ match }: any): any => {

  // Inspired by the Facebook spinners.
  const useStylesFacebook = makeStyles({
    root: {
      position: "relative",
    },
    top: {
      color: "#eef3fd",
    },
    bottom: {
      color: "#6798e5",
      animationDuration: "550ms",
      position: "absolute",
      left: 0
    },
  })

  function FacebookProgress (props: CircularProgressProps) {
    const classes = useStylesFacebook()

    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={80}
          thickness={5}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={80}
          thickness={5}
          {...props}
        />
      </div>
    )
  }

  const goToLogin = () => {
    const goTo = `https://${ config.authDomain }/auth/${ match.params.service }/?redirectUrl=${ window.origin }`
    console.log("GOING TO :", goTo)
    window.location.href = goTo
    return null
  }

  goToLogin()

  return <FacebookProgress />
}
