import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { RouterChanged } from "../auth/RouterChange"
import { Home } from "../pages/Home"
import { Login } from "../pages/Login"
import "./Main.css"

import { InitFirebase } from "../auth/Auth"
import { AuthProvider } from "../auth/AuthContext"

import { HeaderBar } from "../components/HeaderBar"

export const Main = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#6ab7ff',
        main: '#1e88e5',
        dark: '#005cb2',
        contrastText: '#000'
      },
      secondary: {
        light: '#8eacbb',
        main: '#607d8b',
        dark: '#34515e',
        contrastText: '#000'
      }
    }
  })
  return (
    <MuiThemeProvider theme={theme}>
      <AuthProvider>
        <InitFirebase />
        <Router>
          <div className="App">
            <HeaderBar />
            <div className="App-header">
              <RouterChanged />
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login/:service" exact component={Login} />
              </Switch>
            </div>
          </div>
        </Router>
      </AuthProvider>
    </MuiThemeProvider>
  )
}
