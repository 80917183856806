import React from "react"
import { RouteProps } from "react-router"
import { Link } from "react-router-dom"

import { faDiscord, faSteam, faTwitch, IconDefinition } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, CardContent, Divider, Typography } from "@material-ui/core/"

import { AuthedUser } from "./AuthedUser"

import { KeyedUserModel } from "../models"

import "./LoginCard.css"

interface MyProps {
  icon: string
  label: string
  // description: string
  path: string
  authedUsers: KeyedUserModel | []
  loggedIn: boolean
}

interface MyState {
  someString: string,
  loading: boolean
}

interface Icons {
  [name: string]: IconDefinition
}

const icons: Icons = {
  twitch: faTwitch,
  discord: faDiscord,
  steam: faSteam
}

export class LoginCard extends React.Component<MyProps & RouteProps, MyState> {
  public render () {
    const authedUsers = Object.values(this.props.authedUsers)
    return (
      <div className="login-card">
        <Card className="login-card-inner">
          <CardContent>
            <Typography component="h2" color="textPrimary">
              {this.props.loggedIn ? "Connect To" : "Log In With"}
            </Typography>
            <Typography component="h4" color="textPrimary">
              <FontAwesomeIcon icon={icons[this.props.icon]} />&nbsp;
              {this.props.label}
            </Typography>

            {authedUsers.map((value, index, array) => {
              return (
                <div key={index}>
                  <Divider />
                  <AuthedUser authedUser={value} />
                </div>
              )
            })}
          </CardContent>
        </Card>
        <Link to={this.props.path}>
          <Button className="login-button" size="small" variant="contained" color="primary">
            +
          </Button>
        </Link>
      </div>
    )
  }
}
