import * as firebase from "firebase/app"
import "firebase/auth"

import { useContext } from "react"
import { createBrowserHistory } from "history"


import firebaseConfig from "./Firebase"
import { AuthContext, AuthUserContext, getFirestoreUser } from "./AuthContext"

const browserHistory = createBrowserHistory()

firebase.initializeApp(firebaseConfig)
export const InitFirebase = (): null => {
  const context = useContext(AuthContext)
  console.log("initFirebase")

  firebase.auth().onAuthStateChanged(async user => {
    console.log("AUTH STATE CHANGED")

    if (user) {
      console.log("User logged in")
      console.log("user :", user)

      if (!context?.user) {
        context?.setUser(await getFirestoreUser(user))
      }
    } else {
      console.log("No user")
      return
    }
  })

  refreshToken(context)
  return null
}

// browserHistory.listen(async (location, action) => {
//   console.log("location, action :", location, action)
//   // if (location.pathname) await refreshToken()
// })

export const refreshToken = async (context: AuthUserContext | null): Promise<void> => {
  const firebaseUser = firebase.auth().currentUser
  if (firebaseUser) {
    console.log("Got user from existing session!")
    return
  }

  const firebaseToken = getTokenFromUrlHash()
  let loginSucceded = false
  if (firebaseToken !== null) {
    loginSucceded = await loginToFirebase(firebaseToken, context)
  } else if (firebaseToken === null || !loginSucceded) {
    console.log("loginToFirebase did not succeed")
    context?.setUser(null)
    return
  } else {
    removeHash()
  }
}


const removeHash = () => {
  browserHistory.replace(browserHistory.location.pathname + browserHistory.location.search)
}

const getTokenFromUrlHash = (): string | null => {
  const token = browserHistory.location.hash ? browserHistory.location.hash.substr(7) : null
  if (token) {
    removeHash()
  }
  console.log("token :", token)
  return token
}


const loginToFirebase = async (firebaseToken: string, context: AuthUserContext | null): Promise<boolean> => {
  try {
    if (!firebaseToken) {
      context?.setUser(null)
      console.error("We should never get to here. firebaseToken was null!")
      return false
    }
    const user = await firebase.auth().signInWithCustomToken(firebaseToken)
    console.log("loginToFirebase", user)

    context?.setUser(await getFirestoreUser(user?.user))
    return true
  } catch (error) {
    console.error("failed to login", error)
    context?.setUser(null)
    await firebase.auth().signOut()
    return false
  }
}
